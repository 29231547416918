import React from 'react'
import {Input} from "reactstrap"

const EditDatesFields = (props) => {
  const {
    handleDateChange,
    date,
    handleTermsChange,
    paymentsTerms,
    dueDate,
    handleDueDateChange
  } = props

  return (
    <>
      <div className="input-group flex-nowrap mb-3">
        <span className="input-group-text">Date</span>
        <Input
          name="invoicing_date"
          type="date"
          className="form-control"
          value={date}
          onChange={e => handleDateChange(e)}
          required
          id="invoice_date"
        />
      </div>
      <div className="input-group flex-nowrap mb-3">
        <span className="input-group-text">Payment Terms</span>
        <Input
          name="terms"
          type="number"
          className="form-control"
          min={0}
          value={paymentsTerms}
          onChange={e => handleTermsChange(e)}
          autoComplete='off'
          id="invoice_terms"
        />
      </div>
      <div className="input-group flex-nowrap mb-3">
        <span className="input-group-text">Due Date</span>
        <Input
          name="due_date"
          type="date"
          className="form-control"
          value={dueDate}
          onChange={e => handleDueDateChange(e)}
          id="onvoice_due_date"
        />
      </div>
    </>
  )
}

export default EditDatesFields

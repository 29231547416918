import {useContext,useRef} from "react"
import {ModalContext} from "../../../contexts"
import {APIClient} from "../../../helpers/api_helper"
import {toast} from "react-toastify"
import {Button, Col, Form, Input, Label, Row} from "reactstrap"

const ChangeSource = (props) => {
  const {closeModal} = useContext(ModalContext)
  const api = new APIClient
  const inputVal= useRef('')

  const submitHandler = (e) => {
    e.preventDefault()

    api.post('/spa/customer/change-source', {
      customer_source: inputVal.current,
      customer_ids: props.selectedRows.map((i) => i.id)
    }).then((res) => {
      closeModal()
      let message = res?.message || 'Empty response message.'

      if (res.success) {
        toast.success(res.message)
        props.updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <Label className="form-label mb-0">Source</Label>
            <Input
              placeholder="Enter new Source"
              onChange={(e) => {
                inputVal.current = e.target.value
              }}
              id="change_source_input"
            >
            </Input>
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" id="change_source_submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChangeSource

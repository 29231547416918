import React, {useEffect, useState} from 'react'
import {Button, Form, Input, Label} from "reactstrap"
import Select from "react-select"
import {ModalContext} from "../../../../contexts"
import {useContext} from "react"
import {createTransaction, getAllAssets, getTransactionsStatuses} from "../../../../api/transactions"
import {toast} from "react-toastify"
import DatePickerWithParse from "../../../../Components/Common/DatePickerWithParse"
import moment from "moment-timezone"
import {timeZone} from "../../../../helpers/utils"

const WithdrawalTransactionModal = ({id, transactionTableMethodsRef}) => {
  const {closeModal} = useContext(ModalContext)
  const [assetsOptions, setAssetsOptions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const [transactionStatusesOptions, setTransactionStatusesOptions] = useState([])
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState('')

  const getAssetsData = () => {
    getAllAssets().then(r => {
      setAssetsOptions(r.map(i => ({...i, label: i.label + ' / ' + i.code})))

      if (r.length) {
        setSelectedAsset(r[0])
      }
    })
  }

  const getTransactionStatusesData = () => {
    getTransactionsStatuses().then(r => {
      setTransactionStatusesOptions(r)

      if (r.length) {
        setSelectedTransactionStatus(r[2] || r[0])
      }
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    data.date = new Date(moment.tz(data.date, timeZone).format()).toUTCString()

    if (data.amount === '') {
      toast.error('Enter amount')
      btn.disabled = false
    } else {
      createTransaction(data).then(r => {
        closeModal()
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(message)
          btn.disabled = false
          transactionTableMethodsRef?.current?.updateTable()
        } else {
          toast.error(message)
          btn.disabled = false
        }
      }).catch(r => {
        if (r.errors) {
          btn.disabled = false
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      })
    }
  }

  useEffect(() => {
    getAssetsData()
    getTransactionStatusesData()
  }, [])

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <Input hidden={true} name="transaction_type" defaultValue="withdrawal" />
        <div className="mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Asset</Label>
          <Select
            name="asset_id"
            placeholder="Choose asset"
            options={assetsOptions}
            value={selectedAsset}
            onChange={selected => setSelectedAsset(selected)}
            id="withdrawal_asset_select"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Amount</Label>
          <Input type="text" id="withdrawal_amount" className="form-control" placeholder="0.00000000" name="amount" autoComplete="off"/>
        </div>
        <div className="mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Status</Label>
          <Select
            name="status"
            placeholder="Status"
            options={transactionStatusesOptions}
            value={selectedTransactionStatus}
            onChange={selected => setSelectedTransactionStatus(selected)}
            id="withdrawal_status"
          />
        </div>
        <div className="form-group mb-3">
          <Label htmlFor="inputDate" className="form-label mb-0">Date</Label>
          <DatePickerWithParse />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Asset USD Price</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="0.00"
            name="usd_amount"
            autoComplete="off"
            id="withdrawal_asset_usd_price"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Amount in USD</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="0.00"
            name="amount_in_usd"
            autoComplete="off"
            id="withdrawal_amount_in_usd"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Withdraw Address</Label>
          <Input
            type="text"
            className="form-control"
            placeholder=""
            name="withdrawal_address"
            autoComplete="off"
            id="withdrawal_address"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Fee(%)</Label>
          <Input
            type="text"
            list="fee-list"
            className="form-control"
            autoComplete="off"
            placeholder=""
            name="fee_value"
            id="withdrawal_fee"
          />
          <datalist id="fee-list">
            <option value="2">2</option>
            <option value="3.5">3.5</option>
            <option value="5">5</option>
            <option value="7">7</option>
          </datalist>
        </div>
        <div className="mb-4 border-bottom">
          <div className="d-flex">
            <div className="col-6 form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="is_real_check"
                role="switch"
                id="withdrawal_is_real_chack_box"
              />
              <Label className="form-check-label" for="check-box">Is real transaction</Label>
            </div>
            <div className="col-6 form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="is_sync_check"
                role="switch"
                id="withdrawal_is_sync_check_box"
              />
              <Label className="form-check-label" for="check-box">Is sync transaction</Label>
            </div>
          </div>
          <div className="col-6 form-check form-switch mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              name="is_hidden_transaction"
              role="switch"
              id="withdrawal_hidden_check_box"
            />
            <Label className="form-check-label" for="check-box">Hidden</Label>
          </div>
        </div>
        <div className="hstack justify-content-between">
          <div>
            <Button type="button" id="withdrawal_close_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" id="withdrawal_submit_button" color="primary" className="btn">Submit</Button>
          </div>
        </div>
        <Input type="hidden" name="customer_id" defaultValue={id}></Input>
      </Form>
    </React.Fragment>
  )
}

export default WithdrawalTransactionModal

import {useEffect, useState, useContext} from "react"
import {Button, Form, Label} from "reactstrap"
import Select from "react-select"
import {ModalContext} from "../../../contexts"
import {selectListData} from "../../../api/general"
import {sendToRecipient} from "../../../api/customers"
import {toast} from "react-toastify"

const SendToRecipient = ({selectedRows, updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [recipientsOptions, setRecipientsOptions] = useState([])
  const [selectedRecipient, setSelectedRecipient] = useState()

  const submitHandler = (e) => {
    e.preventDefault()

    let data = {
      recipient_id: selectedRecipient.value,
      customer_ids: selectedRows.map(i => i.id)
    }

    sendToRecipient(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
        closeModal()
      }
    })
  }

  useEffect(() => {
    selectListData('recipients').then(r => {
      setRecipientsOptions(r)
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div>
          <Label className="form-label mb-0">Recipient</Label>
          <Select
            placeholder="Choose..."
            value={selectedRecipient}
            onChange={selected => setSelectedRecipient(selected)}
            options={recipientsOptions}
            id="send_to_recipients_select"
          />
        </div>
        <div className="hstack w-100 justify-content-end">
          <Button type="submit" id="send_to_recipients_submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
        </div>
      </Form>
    </>
  )
}

export default SendToRecipient

import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import {toast} from "react-toastify"
import React, {useContext, useEffect, useReducer, useState} from "react"
import {ModalContext} from "../../../contexts"
import Select from "react-select"
import {createCustomer} from "../../../api/customers"
import {selectListData} from "../../../api/general"

function formFieldsReducer(fields, action) {
  switch (action.type) {
    case 'add-invalid': {
      return fields.map(obj => {
        if (obj.name === action.name) {
          return {...obj, invalid: true}
        }

        return obj
      })
    }
    case 'remove-invalid': {
      return fields.map(obj => {
        return {...obj, invalid: false}
      })
    }
    case 'fill-departments': {
      return  fields.map(obj => {
        if (obj.name === 'departments') {
          return {...obj, options: action.data}
        }

        return obj
      })
    }
    case 'fill-country': {
      return  fields.map(obj => {
        if (obj.name === 'country') {
          return {...obj, options: action.data}
        }

        return obj
      })
    }
    case 'fill_options': {
      return  fields.map(obj => {
        if (obj.name === action.name) {
          return {...obj, options: action.data}
        }

        return obj
      })
    }
  }
}

// Can be only one select-multi-single type
const initialFormFields = [
  {name: 'first_name', className: 'form-control', type: 'text', label: "First name"},
  {name: 'last_name', className: 'form-control', type: 'text', label: "Last name"},
  {name: 'email', className: 'form-control', type: 'text', label: "Email"},
  {name: 'phone', className: 'form-control', type: 'text', label: "Phone"},
  {name: 'source', className: 'form-control', type: 'text', label: "Source"},
  {name: 'country', type: 'select', label: "Country", options: []},
  {name: 'password', className: 'form-control', type: 'password', label: "Password"},
  {name: 'departments', type: 'select-multi-single', label: "Department", options: []},
]

const CreateCustomer = (props) => {
  const {closeModal} = useContext(ModalContext)

  const [selectedValues, setSelectedValues] = useState([])
  const [isPassShow, setIsPassShow] = useState(false)
  const [formFields, reduceFormFields] = useReducer(formFieldsReducer, initialFormFields)

  const submitHandler = (e) => {
    e.preventDefault()
    reduceFormFields({
      type: 'remove-invalid',
    })

    let formData = new FormData(e.target)
    formData.delete('departments')

    let data = Object.fromEntries(formData.entries())
    data['departments'] = selectedValues.map(i => i.value)

    createCustomer({...data}).then((res) => {
      closeModal()
      let message = res?.message || 'Empty response message.'

      if (res.success) {
        toast.success(message)
        props.updateTable()
      } else {
        toast.error(message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
          reduceFormFields({
            name: key,
            type: 'add-invalid',
            error: value,
          })
        })
      }
    })
  }

  const selectedValuesHandler = (selected) => {
    setSelectedValues(selected)
  }

  useEffect(() => {
    selectListData('countries').then(r => {
      reduceFormFields({
        type: 'fill-country',
        name: 'country',
        data: r
      })
    })

    selectListData('departments').then(r => {
      reduceFormFields({
        type: 'fill_options',
        name: 'departments',
        data: r
      })
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        {formFields && formFields.map(field => {
          if (field.type === 'select') {
            return (
              <FormGroup key={field.name}>
                <Label htmlFor="choices-multiple-default" className="form-label mb-0">{field.label}</Label>
                <Select
                  placeholder="Choose..."
                  {...field}
                  id={`create_customer_field_${field.name}`}
                />
              </FormGroup>
            )
          } else if (field.type === 'select-multi-single') {
            return (
              <FormGroup key={field.name}>
                <Label htmlFor="choices-multiple-default" className="form-label mb-0">{field.label}</Label>
                <Select
                  placeholder="Choose..."
                  {...field}
                  onChange={(selected) => {
                    selectedValuesHandler(selected)
                  }}
                  isOptionDisabled={() => selectedValues.length >= 1}
                  isMulti
                  id={`create_customer_field_${field.name}`}
                />
              </FormGroup>
            )
          } else {
            return (
            <div className="form-group mb-3" key={field.name}>
              <Label className="form-label mb-0">{field.label}</Label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  {...field}
                  type={field.name === 'password' ? (isPassShow ? "text" : "password") : field.type}
                  placeholder={field.label}
                  autoComplete={'new-password'}
                  id={`create_customer_field_${field.name}`}
                />
                {field.name === 'password' &&
                  <Button
                    color="link"
                    onClick={() => setIsPassShow(!isPassShow)}
                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                    type="button"
                  >
                    <i className="ri-eye-fill align-middle"></i>
                  </Button>
                }
              </div>
              {/*<FormFeedback type="invalid">{field?.error || 'Required'}</FormFeedback>*/}
            </div>)
          }
        })}
        <input type="hidden" name="role" value="customer"/>
        <div className="hstack justify-content-end">
          <Button id="create_customer_submit" type="submit" color="primary" className="btn">Submit</Button>
        </div>
      </Form>
    </>
  )
}

export default CreateCustomer

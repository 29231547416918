import React, {useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {Link} from "react-router-dom"
import {deleteTransactions} from "../../api/transactions"
import {toast} from "react-toastify"
import BaseTable from "../../Components/Tables/BaseTable"
import BaseActionDropdown from "../../Components/Tables/Actions/BaseActionDropdown"
import {DropdownItem} from "reactstrap"
import {ModalContext} from "../../contexts"
import ChangeStatus from "./Modals/ChangeStatus"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import TransactionBadgeIcon from "../../Components/Common/TransactionBadgeIcon"
import {cryptoIconsMapper, isTwoPrecision, prettyDate, toTwoPrecision} from "../../helpers/utils"
import {setColumnsSetting} from "../../slices/transactions/reducer"
import {useSelector} from "react-redux"
import EditTransfer from "./Modals/EditTransfer"
import {filtersList} from "../../api/general"
import _, {capitalize} from "lodash"
import {Tooltip} from "react-tooltip"

const TransactionsTable = ({walletId, withFilters, methodsRef, updateProfile}) => {
  const {openModal} = useContext(ModalContext)
  const [selectedRows, setSelectedRows] = useState([])
  const baseTableMethodsRef = useRef(null)
  const [dataUrl, setDataUrl] = useState('/spa/transactions/data')
  const columnsSetting = useSelector(s => s.Transactions.columnsSetting)
	const [selectedRowIds, setSelectedRowIds] = useState([])
  const [filters, setFilters] = useState()
  const [securitiesLogoUrlValidList, setSecuritiesLogoUrlValidList] = useState([])

  // Storage keys
  const storageName = 'transactions_columns_order'
  const tableRowsPerPage = 'transactions_rows_per_page'
  const filterStorageKey = 'transactions_filter'

	// Permissions
	const canViewCustomer = useUserCan('view_customer')
	const canFilterByDepartment = useUserCan('transaction_departments_filter')
	const canEditTransaction = useUserCan('edit_transaction')
	const canDeleteTransaction = useUserCan('delete_transaction')

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className="font-weight-bold fs-14">ID</span>,
        selector:
          canEditTransaction ?
            row => (
              <a href='#' onClick={() => editTransferHandler(row.id, row.security)}>
                #{row.id}{row.is_real === 'Yes' ? '*' : ''}{!row.is_visible ?
                <i className="ri-eye-off-line fs-10 ms-1"></i> : ''}
              </a>
            ) :
            row => <>#{row.id}{row.is_real === 'Yes' ? '*' : ''}{!row.is_visible ?
              <i className="ri-eye-off-line fs-10 ms-1"></i> : ''}</>,
        minWidth: '90px',
        omit: false,
        reorder: true,
      },
      {
        id: "type",
        name: <span className="font-weight-bold fs-14">Type</span>,
        width: "67px",
        selector: row =>
          <TransactionBadgeIcon
            transactionId={row.id}
            transactionType={row.type}
						invoiceId={row.invoice_id}
						invoiceAmount={row.usd_amount}
					/>,
        reorder: true,
        omit: false
      },
      {
        id: "created_date",
        name: <span className="font-weight-bold fs-14">Created date</span>,
        minWidth: "175px",
        selector: row => (prettyDate(row.created_at)),
        reorder: true,
        omit: false
      },
      {
        id: "base_asset",
        name: <span className="font-weight-bold fs-14">Base Asset</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="avatar-xxs me-2">
              {/*{row.base_asset.currency.code}*/}
              <img
                src={cryptoIconsMapper[row.base_asset.currency.code]}
                className="img-fluid"
                alt={row.base_asset.currency.name}
              />
            </div>
            <span>{row.base_asset.currency.code}</span>
          </div>
        ),
        minWidth: "140px",
        reorder: true,
        omit: false
      },
      {
        id: "quote_asset",
        name: <span className="font-weight-bold fs-14">Quote Asset</span>,
        selector: row => (
          (row.meta?.quantity && Number(row.meta?.quantity) > 0 && !_.isEmpty(row.security)) ?
            <>
              <div
                className="d-flex align-items-center"
                data-tooltip-content={row.security?.name}
                data-tooltip-id={row.id}
              >
                <div className="avatar-xxs me-2 rounded-circle" style={{minWidth: '24px'}}>
                  {securitiesLogoUrlValidList.includes(String(row.id)) ?
                    <div className="avatar-title bg-soft-primary text-primary">
                      {capitalize(row.security?.name.slice(0, 1))}
                    </div>
                    :
                    <img
                      src={row.security?.logo}
                      className="img-fluid h-100"
                      alt={row.security?.name}
                      onError={() => setSecuritiesLogoUrlValidList(prevState => ([...prevState, String(row.id)]))}
                    />
                  }
                </div>
                <div className="text-truncate">{row.security?.name}</div>
              </div>
              <Tooltip id={row.id} style={{zIndex: '2'}}/>
            </>
            :
            row.quote_asset ?
              <div className="d-flex align-items-center">
                <div className="avatar-xxs me-2">
                  <img
                    src={cryptoIconsMapper[row.quote_asset.currency.code]}
                    className="img-fluid"
                    alt={row.base_asset.currency.name}
                  />
                </div>
                <span>{row.quote_asset.currency.code}</span>
              </div> :
              '-'
        ),
        minWidth: "140px",
        reorder: true,
        omit: false
      },
      {
        id: "customer",
        name: <span className="font-weight-bold fs-14">Customer</span>,
        selector: row => (canViewCustomer ?
            <Link to={`/customers/show/${row.wallet.user?.id}`}>#{row.wallet.user?.id} {row.wallet.user?.name}</Link> :
            <span>#{row.wallet.user?.id} {row.wallet.user?.name}</span>
        ),
        minWidth: '180px',
        reorder: true,
        omit: false
      },
      {
        id: "amount",
        name: <span className="font-weight-bold fs-14">Amount</span>,
        selector: row => isTwoPrecision(row.base_asset.currency.code, row.base_amount),
        minWidth: '115px',
        reorder: true,
        omit: false
      },
      {
        id: "usd_amount",
        name: <span className="font-weight-bold fs-14">USD Amount</span>,
        selector: row => '$' + toTwoPrecision(row.usd_amount || 0),
        minWidth: '100px',
        reorder: true,
        omit: false
      },
			{
				id: "fee",
				name: <span className="font-weight-bold fs-14">Fee</span>,
				selector: (row) => {
					if (row.type === 'exchange') {
						return isTwoPrecision(row.quote_asset.currency.code, row.total_fee);
					} else if (row.type === 'deduction') {
						return isTwoPrecision(row.base_asset.currency.code, row.total_fee);
					} else {
						return '-';
					}
				},
        reorder: true,
        omit: false
			},
      {
        id: "fee_pct",
        name: <span className="font-weight-bold fs-14">Fee Pct</span>,
        selector: row => <span>{Number(row.fee_pct)}%</span>,
        omit: false,
        reorder: true,
      },
      {
        id: "status",
        name: <span className="font-weight-bold fs-14">Status</span>,
        selector: row => (
          <span
            id={row.status === 'pending' ? (
              `pending_${row.id}`
              ) : (row.status === 'executed' ? (
              `executed_${row.id}`
              ) : (row.status === 'declined' ?
                `declined_${row.id}` : row.id
              ))}
            className={'badge badge-soft-' + row.status_color}>
                          {row.status === 'pending' ? (
	                          <i className="ri-time-line align-bottom me-1"></i>
                          ) : (row.status === 'executed' ? (
	                          <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                          ) : (row.status === 'declined' ?
		                          <i className="ri-close-circle-line align-bottom me-1"></i> : ''
                          ))}
						{row.status_upper}
                        </span>
				),
				minWidth: '105px',
        reorder: true,
        omit: false
			},
			{
				id: "is_real",
				name: <span className="font-weight-bold fs-14">Is Real</span>,
				selector: row => row.is_real,
        omit: false,
        reorder: true,
			},
			{
				id: "is_sync",
				name: <span className="font-weight-bold fs-14">Is Sync</span>,
				selector: row => row.is_sync,
        omit: false,
        reorder: true,
			},
			{
				id: "actions",
				name: <span className="font-weight-bold fs-14">Actions</span>,
				selector: row => (
					<>
						{(canDeleteTransaction || canEditTransaction) &&
							<div className="hstack gap-3 flex-wrap">
								{canDeleteTransaction &&
									<Link
										to="#"
										onClick={() => {
											deleteTransactionsHandler([row.id])
										}}
										className="link-danger fs-15">
										<i className="ri-delete-bin-line"></i>
									</Link>}
							</div>}
					</>
				),
        omit: false,
        reorder: true,
			}
		], [canEditTransaction, canDeleteTransaction, canViewCustomer, securitiesLogoUrlValidList])

  const getFilterData = () => {
    filtersList( filterStorageKey).then(r => {
      setFilters(r)
    })
  }

  useEffect(() => {
    if (withFilters) {
      getFilterData()
    }
  }, [canFilterByDepartment, withFilters]);

	const updateTable = () => {
		if (updateProfile) {
			updateProfile()
		}
		baseTableMethodsRef.current.updateTable()
	}

  const clearingSelectedRowsList = () => {
    setSelectedRowIds([])
  }

	useImperativeHandle(methodsRef, () => ({
		updateTable: () => {
			updateTable()
		}
	}))

	const deleteTransactionsHandler = (ids) => {
		if (!ids) ids = selectedRows.map(i => i.id)

		if (confirm('Are you sure to delete transactions with ids: ' + ids.join(', '))) {
			deleteTransactions(ids).then(r => {
				toast.success(r.message)
        updateTable()
        setSelectedRows([])
        setSelectedRowIds([])
      })
		}
	}

	const selectedRowsHandler = (row) => {
		setSelectedRows(row)
		setSelectedRowIds(row.map((row) => row.id))
	}

	const changeStatusHandler = () => {
		openModal({
			title: 'Change Status',
			content: <ChangeStatus selectedRows={selectedRows} updateTable={updateTable}/>
		})
	}

  const editTransferHandler = (id, security) => {
    openModal({
      title: 'Edit transaction',
      content: <EditTransfer
        transferId={id}
        security={security}
        selectedRows={selectedRows}
        updateTable={updateTable}
        securitiesLogoUrlValidList={securitiesLogoUrlValidList}
      />,
      size: 'xl',
      fullscreen: 'lg',
    })
  }

	useEffect(() => {
    if (walletId) {
      updateTable()
    }
	}, [walletId])

  const contextActions = (
		<BaseActionDropdown key="actionsDropdown">
			{canEditTransaction &&
				<>
					<DropdownItem onClick={changeStatusHandler}>Change status</DropdownItem>
        </>
			}
			{canDeleteTransaction &&
				<DropdownItem onClick={() => {
					deleteTransactionsHandler(selectedRows.map(i => i.id))
				}}>
					Delete
				</DropdownItem>}
		</BaseActionDropdown>
	)

	return (
		<BaseTable
			columns={columns}
			title="Transactions"
			filterFields={withFilters ? filters : []}
			url={dataUrl}
			additionalRequest={walletId ? {wallet_id: walletId} : null}
			selectedRowsHandler={selectedRowsHandler}
			methodsRef={baseTableMethodsRef}
			actions={contextActions}
			columnsSetting={columnsSetting}
			columnsSettingHandler={setColumnsSetting}
      conditionalRowStyles={[{when: row => !row.row_color, classNames: ['rdt_Table-row-odd']},
				{when: (row) => selectedRowIds.includes(row.id),
					classNames: ['selected_Row']
				}]}
      storageName={storageName}
      tableRowsPerPage={tableRowsPerPage}
      clearingSelectedRowsList={clearingSelectedRowsList}
      filterStorageKey={filterStorageKey}
      // conditionalRowStyles={[{when: row => !row.row_color, style: {backgroundColor: '#fff'}}]}
		/>
	)
}

export default TransactionsTable

import React, {useState, useEffect} from "react"
import {Input, Button, Form, Table, Container} from "reactstrap"
import moment from "moment"
import {createInvoice, getUuid} from "../../api/invoices"
import {toast} from "react-toastify"
import {selectListData} from "../../api/general"
import {useNavigate, useParams} from "react-router-dom"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {getProfile} from "../../api/customers"
import Select from "react-select"
import EditDatesFields from "./Components/EditDatesFields";
import ItemsEditRowsTable from "./Components/ItemsEditRowsTable";

const Invoice = () => {
  const navigator = useNavigate()

  const {id} = useParams()
  const [uuid, setUuid] = useState('')
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [paymentsTerms, setPaymentsTerms] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [selectedFromOption, setSelectedFromOption] = useState('')
  const [selectFromOptions, setSelectFromOptions] = useState([])
  const [selectAsset, setSelectAsset] = useState('')
  const [assetOptions, setAssetOptions] = useState([])
  const [subtotal, setSubtotal] = useState(0.00)
  const [discount, setDiscount] = useState('')
  const [tax, setTax] = useState('')
  const [dueDate, setDueDate] = useState(moment())
  const [customerData, setCustomerData] = useState('')

  const [customFrom, setCustomFrom] = useState({
    custom_name_from: '',
    custom_address: '',
    custom_info: '',
  })

  const [rows, setRows] = useState([
    {
      id: 1,
      product_description: '',
      quantity: '',
      rate: '',
      amount: 0.00,
    }
  ])

  const addRow = () => {
    const newRow = {
      id: Math.max(...rows.map(row => row.id)) + 1,
      product_description: '',
      quantity: '',
      rate: '',
      amount: 0.00,
    }

    setRows([...rows, newRow])
  }

  const deleteRow = (id) => {
    if (rows.length === 1) {
      const newId = rows[0].id + 1
      setRows([{
        id: newId,
        product_description: '',
        quantity: '',
        rate: '',
        amount: 0.00,
      }])
    } else {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const calculateDiscountAmount = () => {
    const discountValue = parseFloat(discount)
    if (isNaN(discountValue) || !discount) {
      return 0
    }

    return (subtotal * (discountValue / 100))
  }

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }

  const handleCustomFromChange = (fieldName, value) => {
    setCustomFrom((prevFields) => ({
      ...prevFields,
      [fieldName]: value
    }))
  }

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.options[e.target.selectedIndex].value)
  }

  const handleAssetChange = (e) => {
    setSelectAsset(e.target.options[e.target.selectedIndex].value)
  }

  const handleTermsChange = (e) => {
    const newTermsDays = e.target.value
    setPaymentsTerms(newTermsDays)
    calculateDueDate(newTermsDays)
  }

  const handleDateChange = (e) => {
    const newDate = e.target.value
    setDate(newDate)
    calculateDueDate(paymentsTerms, newDate)
  }

  const handleDueDateChange = (e) => {
    const newDueDate = e.target.value
    setDueDate(newDueDate)

    const dueDateFormatted = moment(newDueDate, 'YYYY-MM-DD')
    const invoiceDateFormatted = moment(date, 'YYYY-MM-DD')

    if (dueDateFormatted.isValid() && invoiceDateFormatted.isValid()) {
      const newTermsDays = dueDateFormatted.diff(invoiceDateFormatted, 'days')
      setPaymentsTerms(newTermsDays)
    }
  }

  const calculateDueDate = (daysCount, invoiceDate = date) => {
    const dueDateOffset = daysCount || parseInt(paymentsTerms, 10)
    const calculatedDueDate = moment(invoiceDate).add(dueDateOffset, 'days')

    setDueDate(formatDate(calculatedDueDate))
  }

  const handleQuantityChange = (event, id) => {
    const newValue = event.target.value
    if (!isNaN(newValue) || newValue === "") {
      updateRow(id, {quantity: newValue})
    }
  }

  const handleRateChange = (event, id) => {
    const newValue = event.target.value
    if (!isNaN(newValue) || newValue === "") {
      updateRow(id, {rate: newValue})
    }
  }

  const handleProductDescriptionChange = (event, id) => {
    const newValue = event.target.value
      updateRow(id, {product_description: newValue})
  }

  const updateRow = (id, updateValue) => {
    const updateRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = {...row, ...updateValue}
        updatedRow.amount = updatedRow.quantity * updatedRow.rate

        return updatedRow
      }

      return row
    })
    setRows(updateRows)
  }

  const sendInvoiceHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let customLogo = e.target.elements?.custom_logo

    const formData = new FormData()
    formData.append('customer_id', id)
    formData.append('invoicing_date', date)
    formData.append('due_date', dueDate)
    formData.append('invoice_id', `C-${uuid}`)
    formData.append('payments_terms', paymentsTerms)
    formData.append('total_amount', totalAmountValue)
    formData.append('currency_id', currencyOptions.find(option => option.value === Number(selectedCurrency))?.value)
    formData.append('payout_asset_id', assetOptions.find(option => option.value === Number(selectAsset))?.value)
    formData.append('mail_from', selectedFromOption.value)
    formData.append('subtotal_amount', subtotal)
    formData.append('discount', discount)
    formData.append('tax', tax)
    formData.append('custom_name_from', customFrom.custom_name_from)
    formData.append('custom_address', customFrom.custom_address)
    formData.append('custom_info', customFrom.custom_info)
    formData.append('rows', JSON.stringify(rows))

    if (customLogo) {
      formData.append('custom_logo', customLogo.files[0])
    }

    if (formData.get('payout_asset_id') === 'undefined') {
      toast.error('Select payout asset')
      btn.disabled = false
    } else if (formData.get('currency_id') === 'undefined') {
      toast.error('Select currency')
      btn.disabled = false
    } else {
      createInvoice(formData).then(res => {
        let message = res?.message || 'Empty response message.'

        if (res.success) {
          toast.success(message)
          navigator(`/customers/show/${id}`)
        } else {
          toast.error(message)
        }
      }).catch(r => {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        } else {
          toast.error(r)
        }
      }).finally(() => btn.disabled = false)
    }
  }

  useEffect(() => {
    const newSubtotal = rows.reduce((acc, row) => acc + row.amount, 0)
    setSubtotal(newSubtotal)
  }, [rows])

  useEffect(() => {
    selectListData('fiat_assets').then((r) => {
      setCurrencyOptions(r)
    })

    selectListData('crypto_assets_ids').then((r) => {
      setAssetOptions(r.map(i => ({...i, label: i.label + ' / ' + i.code})))
    })

    selectListData('invoices_select_from_list').then((r) => {
      setSelectFromOptions(r)
    })

    getUuid().then(r => {
      setUuid(r)
    })

    getProfile(id).then((r) => {
      setCustomerData(r)
    })
  }, [])

  const discountAmount = calculateDiscountAmount()
  const taxAmount = (subtotal - discountAmount) * (tax / 100)
  const totalAmountValue = subtotal - discountAmount + taxAmount

  document.title = 'Create invoice | ' + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Create invoice"/>
          <Form onSubmit={sendInvoiceHandler}>
            <div className="card shadow p-5">
              <div className="card-body">
                <div className="mb-3 pb-3 border-bottom d-flex">
                  <div className="col-4 col-lg-6 col-sm-6 fs-22">Invoice</div>
                  <div className="col-8 col-lg-6 col-sm-6 d-flex justify-content-end">
                    <div className="input-group mx-3">
                      <select
                        className="form-select"
                        aria-label="form-select-lg"
                        onChange={handleAssetChange}
                        required
                        id="incoice_payout_asset"
                      >
                        <option>Select payout asset</option>
                        {assetOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>)
                        )}
                      </select>
                    </div>
                    <div className="input-group">
                      <select
                        className="form-select"
                        aria-label="form-select-sm"
                        onChange={handleCurrencyChange}
                        required
                        id="invoice_currency"
                      >
                        <option>Select currency</option>
                        {currencyOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>)
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-sm-6">
                    <div className="mb-2">
                      <Select
                        name="mail_from"
                        placeholder="Select from"
                        options={selectFromOptions}
                        value={selectedFromOption}
                        onChange={selected => setSelectedFromOption(selected)}
                        required
                        id="from_brand_select"
                      >
                      </Select>
                    </div>

                    {selectedFromOption.value === "custom" && (
                      <div className="custom-from input-group">
                        <div className="mb-3 col-12">
                          <label htmlFor="custom_logo" className="form-label">Download Logo</label>
                          <input
                            name="custom_logo"
                            className="form-control"
                            aria-label="form-control-lg"
                            id="custom_logo"
                            type="file"
                            required
                          />
                        </div>
                        <div className="mb-3 col-12">
                          <Input
                            name="custom_name_from"
                            className="form-control"
                            type="text"
                            required
                            id='custom_name_from'
                            placeholder="Enter company name"
                            value={customFrom.custom_name_from}
                            onChange={(e) =>
                              handleCustomFromChange('custom_name_from', e.target.value)
                            }/>
                        </div>
                        <div className="mb-3 col-12">
                          <Input
                            type="textarea"
                            name="custom_address"
                            className="form-control"
                            id="custom_address"
                            placeholder="Enter address"
                            value={customFrom.custom_address}
                            onChange={(e) =>
                              handleCustomFromChange('custom_address', e.target.value)
                            }/>
                        </div>
                        <div className="mb-3 col-12">
                          <Input
                            name="custom_info"
                            className="form-control"
                            type="text"
                            id="custom_info"
                            placeholder="Enter info"
                            value={customFrom.custom_info}
                            onChange={(e) =>
                              handleCustomFromChange('custom_info', e.target.value)
                            }/>
                        </div>
                      </div>
                    )}

                    <div>
                      <h6 className="mb-3">To:</h6>
                      <div><strong>{customerData?.data?.name}</strong></div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <h4 type="text">
                        INVOICE
                      </h4>
                    </div>
                    <div className="input-group flex-nowrap mb-5">
                      <span className="input-group-text">ID</span>
                      <Input
                        name="invoice_id"
                        type="text"
                        className="form-control minus"
                        value={uuid}
                        readOnly
                      />
                    </div>

                    <EditDatesFields
                      handleDateChange={handleDateChange}
                      date={date}
                      handleTermsChange={handleTermsChange}
                      paymentsTerms={paymentsTerms}
                      dueDate={dueDate}
                      handleDueDateChange={handleDueDateChange}
                    />

                  </div>
                </div>
                <div className="table-responsive">

                  <ItemsEditRowsTable
                    rows={rows}
                    handleProductDescriptionChange={handleProductDescriptionChange}
                    handleQuantityChange={handleQuantityChange}
                    handleRateChange={handleRateChange}
                    currencyOptions={currencyOptions}
                    selectedCurrency={selectedCurrency}
                    deleteRow={deleteRow}
                    addRow={addRow}
                  />

                </div>
                <div className="col-lg-4 col-sm-5 ms-auto">
                  <table className="table table-clear">
                    <tbody>
                    <tr>
                      <td><strong>Subtotal</strong></td>
                      <td className="text-end">
                        <span className="subtotal-amount">
                          {subtotal.toFixed(2)}{' '}
                          {currencyOptions.find(option => option.value === selectedCurrency)?.label}
                          <span className="payout-asset"></span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><span className="input-group-text">Discount</span></td>
                      <td>
                        <div className="input-group flex-nowrap">
                          <Input
                            pattern="\d+?"
                            name="discount"
                            id="discount_input"
                            type="text"
                            className="calc-input form-control text-end"
                            defaultValue={discount}
                            onChange={(e) => {
                              const value = e.target.value
                              const formattedValue = value.replace(",", ".");
                              if (formattedValue === "" || !isNaN(parseFloat(value))) {
                                setDiscount(formattedValue)
                              }
                            }}
                            placeholder="0 %"
                            autoComplete="off"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="input-group-text">Tax</span>
                      </td>
                      <td>
                        <div className="input-group flex-nowrap">
                          <Input
                            pattern="\d+?"
                            name="tax"
                            id="tax_input"
                            type="text"
                            className="calc-input form-control text-end"
                            defaultValue={tax}
                            onChange={(e) => {
                              const value = (e.target.value)
                              const formattedValue = value.replace(",", ".");
                              if (formattedValue === "" || !isNaN(parseFloat(value))) {
                                setTax(formattedValue)
                              }
                            }}
                            placeholder="0 %"
                            autoComplete="off"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td className="text-end"><strong><span className="total-amount">
                    {totalAmountValue.toFixed(2)}{' '}
                        {currencyOptions.find((option) => option.value === selectedCurrency)?.label}
                  </span></strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <div className="col-12 text-end">
                    <Button type="submit" id="create_invoice_button" className="btn btn-primary my-1" color="primary">
                      <i className="fa fa-paper-plane-o"></i>
                      Create Invoice
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Invoice

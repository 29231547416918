import {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {APIClient} from "../../../helpers/api_helper"
import {toast} from "react-toastify"
import {Button, Col, Form, Label, Row} from "reactstrap"
import Select from "react-select"
import {changeDepartment} from "../../../api/customers";
import {selectListData} from "../../../api/general";

const ChangeCustomerDepartment = (props) => {
  const {closeModal} = useContext(ModalContext)
  const api = new APIClient
  const [selectedValue, setSelectedValue] = useState(null)
  const [options, setOptions] = useState([])

  useEffect(() => {
    selectListData('departments').then((resp) => {
      setOptions(resp)
    })
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()

    changeDepartment(selectedValue.value, props.selectedRows.map((i) => i.id)).then((res) => {
      closeModal()
      let message = res?.message || 'Empty response message.'

      if (res.success) {
        toast.success(res.message)
        props.updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <Label className="form-label mb-0">Department</Label>
            <Select
              placeholder="Choose..."
              value={selectedValue}
              onChange={(selected) => {
                setSelectedValue(selected)
              }}
              options={options}
              id="change_customer_department_select"
            />
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" id="change_customer_department_submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChangeCustomerDepartment

import {useContext, useEffect, useState} from "react";
import {Button, Col, Form, Label, Row} from "reactstrap";
import Select from "react-select";
import {toast} from "react-toastify";
import {ModalContext} from "../../../contexts";
import {attachEmployee} from "../../../api/customers";
import {selectListData} from "../../../api/general";

const AttachEmployee = (props) => {
  const {closeModal} = useContext(ModalContext);
  const [selectedValue, setSelectedValue] = useState(null)
  const [options, setOptions] = useState([])

  useEffect(() => {
    selectListData('agents').then((r) => {
      setOptions(r)
    })
  }, [])

  const submitHandler = (e) => {
    e.preventDefault();

    attachEmployee(selectedValue.value, props.selectedRows.map((i) => i.id))
      .then((res) => {
        closeModal()
        let message = res?.message || 'Empty response message.'

        if (res.success) {
          toast.success(res.message)
          props.updateTable()
        } else {
          toast.error(message)
        }
      });
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <Label className="form-label mb-0">Employee</Label>
            <Select
              placeholder="Choose..."
              value={selectedValue}
              onChange={(selected) => {
                setSelectedValue(selected)
              }}
              options={options}
              id='attach_employe_select'
            />
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" id='attach_employe_submit' color="primary" className="btn ms-auto mt-4">Submit</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AttachEmployee
